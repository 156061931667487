import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { IconGithub, IconExternal, IconFolder } from '@components/icons';
import styled from 'styled-components';
import { theme, mixins, media, Section, Button, Heading } from '@styles';
const { colors, fontSizes, fonts } = theme;

const ProjectsContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
`;
const ProjectsTitle = styled.h4`
  margin: 0 auto 50px;
  font-size: ${fontSizes.h3};
  ${media.tablet`font-size: 24px;`};
  a {
    display: block;
  }
`;
const ProjectsGrid = styled.div`
  .projects {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    ${media.desktop`grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));`};
  }
`;

const ProjectInner = styled.div`
  ${mixins.boxShadow};
  ${mixins.flexBetween};
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2rem 1.75rem;
  height: 100%;
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  background-color: ${colors.lightNavy};
`;
const Project = styled.div`
  transition: ${theme.transition};
  cursor: default;
  &:hover,
  &:focus {
    outline: 0;
    ${ProjectInner} {
      transform: translateY(-5px);
    }
  }
`;
const ProjectHeader = styled.div`
  ${mixins.flexBetween};
  margin-bottom: 30px;
`;
const Folder = styled.div`
  color: ${colors.green};
  svg {
    width: 40px;
    height: 40px;
  }
`;
const Links = styled.div`
  margin-right: -10px;
  color: ${colors.lightSlate};
`;
const IconLink = styled.a`
  position: relative;
  top: -10px;
  padding: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
`;
const ProjectName = styled.h5`
  margin: 0 0 10px;
  font-size: 35px;
  text-align: center;
  color: ${colors.lightestSlate};
`;

const ProjectPrice = styled.h5`
  margin: 0 0 10px;
  font-size: 17px;
  text-align: center;
  color: ${colors.lightestSlate};
  a {
    font-size: 35px;
  }
`;
const ProjectDescription = styled.div`
  font-size: 17px;
  color: ${colors.lightSlate};
  a {
    ${mixins.inlineLink};
  }
`;
const TechList = styled.ul`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 20px;
  li {
    font-family: ${fonts.SFMono};
    font-size: ${fontSizes.xsmall};
    color: ${colors.slate};
    line-height: 1.75;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
const ShowMoreButton = styled(Button)`
  margin: 100px auto 0;
`;

const Projects = ({ data }) => {
  const [showMore, setShowMore] = useState(false);
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const GRID_LIMIT = 6;
  const projects = data.filter(({ node }) => node.frontmatter.show === 'true');
  const firstSix = projects.slice(0, GRID_LIMIT);
  const projectsToShow = showMore ? projects : firstSix;

  return (
    <ProjectsContainer id="investment">
      <Heading>Your Investment</Heading>

      <ProjectName>Sometimes I feel it would be too pricey...</ProjectName>
      <p>
        So, here's some perspective on Neurafarm Enterprise Solutions' value so you can make a wiser choice.
      </p>

      
      <ProjectName>First of all, No Data Limits</ProjectName>
      <p>
      When you use Neurafarm Enterprise Solutions (NES) On-Premise, it's totally up to you  how much data  you process. No other tool can give you this freedom, which is a big bonus if your business has demanding data requirements.
      So long the amount of land covered is still the same with the time you bought the license. Different land size will require re-calculation of license fee.
      </p>

      <ProjectName>Second of all..</ProjectName>
      <p>
        Let's calculate some things. Without this system, we think usually GGF will do the following:
      </p>

      <p>
      <ol>
        <li>1. Activity: Fruit counting (sampling), Person: Team (5 person), Time: 15 hours/week, which is equal to min. 75 hours, Cost: ?</li>
        <li>2. Activity: Collecting, comparing, and correcting stats, Person: Assistant, Time: 3 hours/week, Cost: ?</li>
        <li>3. Activity: Managing deployment, reviewing numbers: Person: Supervisor, Time: 2 hours/week, Cost: ?</li>
      </ol>
      </p>

      <p>
          Even if we disregard opportunity cost and consider the time and costs in this example are exaggerated, our solution will save you <b>minimum 320 hours every month.</b>
      </p>


      <ProjectName>Lastly, let's think beyond prices</ProjectName>
      <p>
        Not using Neurafarm's solution and...
      </p>
      <p>
        <ul>
        <li>1. building, maintaining a custom system, and hiring great engineers on your own is more risky</li>
        <li>2. taking decisions based on inaccurate/incomplete reporting is not ideal.</li>
        <li>3. not using analytics/data science to grow business means you're losing the opportunity to get to know your business better and save time.</li>
        </ul>
      </p>

      <p>Think about it.</p>
      
      <ProjectName>Okay, ready for the strategy?</ProjectName>
      <p>
        Our pricing is set in a way that it will actually minimize your risk in terms of cost &amp;
        time, that's why we created several development phase and various packages that can be
        chosen as per your needs. There's a little non-refundable payment upfront to get us started
        for Phase 1 (PoC), but after Phase 1 is finished, you can choose whether to continue or not. you
        only pay if you are comfortable and want to proceed to the next steps.

        Please note that if you decided to not proceed to the next steps, we will delete all the files, including the data and code that we created from the PoC phase.
      </p>

      <ProjectName>Get result first, pick a licensing plan later</ProjectName>

      <p>
        After some calculation, the <b>full working product</b> will require <b>Customization Fee</b> of at least <b>$12,499</b> and deployment fee is around $275/person/day for our engineer to deploy the solution to your premise on-site. This is the plan:
      </p>


      <table>
        <tr>
          <th>Payment</th>
          <th>Percentage</th>
          <th>Cost</th>
        </tr>
        <tr>
          <td>PoC</td>
          <td>30%</td>
          <td>$3750</td>
        </tr>
        <tr>
          <td>Requirements</td>
          <td>25%</td>
          <td>$3125</td>
        </tr>
        <tr>
          <td>Finishing (Phase 3 &amp; 4)</td>
          <td>45%</td>
          <td>$5624</td>
        </tr>
      </table>
      <p></p>

      <p>
  Firstly, the PoC phase will require only $3750 (30% Customization Fee) deposit upon signing of the proposal. We'll then carry out Phase 1 (Proof of Concept).{' '}
      </p>
      <p>$3750 seems like a lot of money. But to put things into perspective... if the PoC (Phase 1) process is done within 3 months, it means that we spend $1250 per month. We use dedicated server instance and our engineers to build the software. A good Machine Learning Engineer alone could cost you up to $700 per month. If you were to choose to develop it on your own, you also have to recruit a good Product Manager, and pay for Machine Learning Server cost (which could be as high as $14 dollar/hour, or maybe $4/hour for lower-end server. And the training process could take up roughly 10+ hours per day). Basically, you'll have to spend at least $2500 or $3000 per month if you do it by yourself. So, let's think of the price we offered as the cost to prove the idea and build a prototype, but way cheaper.</p>
      <p>Secondly, $3125 or around 25% of Customization Fee deposit upon starting Phase 2 (Requirements &amp; Development). At this phase, <b>at this phase you will also have to choose one of the plans that we offered below. Then, we carry out Phase 2.</b> The licensing plan will be charged as soon as the deployment is done.</p>
      <p>Then, the rest of the payment ($5624) for Customization Fee is paid prior to Deployment &amp; Integration.</p>
      <p>Lastly, Integration Fee are paid after completion of Deployment &amp; Integration.</p>

      <p>
        <b>*Exclude: Taxes, Travel Cost &amp; Accomodation</b>
      </p>
      <p>
        <b>*The amount of customization fee will be adjusted according to the amount of features and complexity of the final product that is gathered in Phase 2 (Requirements gathering)</b>
      </p>
      <p>
        <b>*License and Maintenace plan should be chosen at Phase 2.</b>
      </p>


      <ProjectName>License, Maintenance, and Beyond</ProjectName>
      <p>
        After development process is done, We offer 4 types of license based on the duration of the period for which you can use the
        software: Annual, 2 Year, 3 Year, and Perpetual License. All packages implemented in a non-exclusive rights to the use of a license agreement.
      </p>

      <p>
        Support includes a dedicated solution engineer, online communication via e-mail, chat or by phone, 9am-3pm, Monday-Friday.
        Additional fee of $350 a day will be enforced for on-site support.
      </p>
      <p>Most update will be conducted online.</p>
      <p>
        <b>*All pricing exclude: Taxes, Travel Cost &amp; Accomodation</b>
      </p>


      <ProjectsGrid>
        <TransitionGroup className="projects">
          {projectsToShow &&
            projectsToShow.map(({ node }, i) => {
              const { frontmatter, html } = node;
              const {
                github,
                external,
                title,
                tech,
                customization,
                integration,
                support,
                license,
              } = frontmatter;
              return (
                <CSSTransition
                  key={i}
                  classNames="fadeup"
                  timeout={i >= GRID_LIMIT ? (i - GRID_LIMIT) * 300 : 300}
                  exit={false}>
                  <Project
                    key={i}
                    ref={el => (revealProjects.current[i] = el)}
                    tabIndex="0"
                    style={{
                      transitionDelay: `${i >= GRID_LIMIT ? (i - GRID_LIMIT) * 100 : 0}ms`,
                    }}>
                    <ProjectInner>
                      <header>
                        <ProjectHeader>
                          <Links>
                            {github && (
                              <IconLink
                                href={github}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                aria-label="Github Link">
                                <IconGithub />
                              </IconLink>
                            )}
                            {external && (
                              <IconLink
                                href={external}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                                aria-label="External Link">
                                <IconExternal />
                              </IconLink>
                            )}
                          </Links>
                        </ProjectHeader>
                        <ProjectName>{title}</ProjectName>
                        <ProjectDescription dangerouslySetInnerHTML={{ __html: html }} />
                        <ProjectPrice>
                          Support: <a>{support}</a>{' '}
                        </ProjectPrice>
                        <ProjectPrice>
                          Maintenance &amp; Updates: <a>{license}</a>{' '}
                        </ProjectPrice>
  
                      </header>
                      <footer>
                        <TechList>
                          {/* {tech.map((tech, i) => (
                            <li key={i}>{tech}</li>
                          ))} */}
                        </TechList>
                      </footer>
                    </ProjectInner>
                  </Project>
                </CSSTransition>
              );
            })}
        </TransitionGroup>
      </ProjectsGrid>
      <p></p>
      <p></p>

      <ProjectName>Prevent losses</ProjectName>
      <p>
        The primary goals of this system is to help GGF prevent losses because of unoptimal operations (harvesting, etc). 
        This system does exactly that, hence it will improve GGF production. It also does the work of an employee – taking care of reporting, analysis and helping with managing the operation. It's just faster.. And we believe dollar to dollar, Neurafarm is the best investment for the future of your businesses.

      </p>


      <ProjectName>We are committed to Data Privacy</ProjectName>
      <p>
        We take being stewards of your data very seriously. Neurafarm respects your data and
        maintains strict policies on how it can be used. Above all else, we’re using our data set to
        develop better products for our customers.
      </p>

      <ProjectName>Additional Information &amp; Notes </ProjectName>
      <p>- All pricing is in USD</p>
      <p>- Pricing is per month but billed annually, except otherwise stated; e.g Perpetual License.</p>
      <p>
        - All package applies for <b>unlimited use</b> for 0-1500 (on thousand and five hundred) ha of land.
      </p>
      <p>
        - All pricing cycle lasts for 2 years. After two years, each package increases by 15%.
      </p>
      <p>
        - This project is specific for Pineapple. Different commodities will require different machine learning models.
      </p>
      <p>
        - All pricing is approximated and calculated the best we can. We want to help you to solve
        your business problems and we'll be happy to discuss any other options or package that suits
        your needs. Everything is negotiatble with us, so don't hesitate to let us know.
      </p>

      <ProjectName>Special deal?</ProjectName>
      <p>Still looking for other alternative packages and other pricing strategy? Let's talk</p>
    </ProjectsContainer>
  );
};

Projects.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Projects;
