import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import sr from '@utils/sr';
import { srConfig } from '@config';
import { IconGithub, IconExternal } from '@components/icons';
import styled from 'styled-components';
import { theme, mixins, media, Section, Heading } from '@styles';
const { colors, fontSizes, fonts } = theme;

const FeaturedContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
`;
const ContentContainer = styled.div`
  position: relative;
  grid-column: 1 / 7;
  grid-row: 1 / -1;
  ${media.thone`
    grid-column: 1 / -1;
    padding: 40px 40px 30px;
    z-index: 5;
  `};
  ${media.phablet`padding: 30px 25px 20px;`};
`;
const FeaturedLabel = styled.h4`
  font-size: ${fontSizes.smallish};
  font-weight: normal;
  color: ${colors.green};
  font-family: ${fonts.SFMono};
  margin-top: 10px;
  padding-top: 0;
`;
const ProjectName = styled.h5`
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 20px;
  color: ${colors.lightestSlate};
  ${media.tablet`font-size: 24px;`};
  ${media.thone`color: ${colors.white};`};
  a {
    ${media.tablet`display: block;`};
  }
`;
const ProjectDescription = styled.div`
  ${mixins.boxShadow};
  position: relative;
  z-index: 2;
  padding: 25px;
  background-color: ${colors.lightNavy};
  color: ${colors.lightSlate};
  font-size: ${fontSizes.large};
  border-radius: ${theme.borderRadius};
  ${media.thone`
    background-color: transparent;
    padding: 20px 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `};
  p {
    margin: 0;
  }
  a {
    ${mixins.inlineLink};
  }
`;
const TechList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 10px;
  li {
    font-family: ${fonts.SFMono};
    font-size: ${fontSizes.smallish};
    color: ${colors.slate};
    margin-right: ${theme.margin};
    margin-bottom: 7px;
    white-space: nowrap;
    &:last-of-type {
      margin-right: 0;
    }
    ${media.thone`
      color: ${colors.lightestSlate};
      margin-right: 10px;
    `};
  }
`;
const Links = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-left: -10px;
  color: ${colors.lightestSlate};
  a {
    padding: 10px;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
const FeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border-radius: ${theme.borderRadius};
  position: relative;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1) brightness(90%);
  ${media.tablet`
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(80%);
  `};
`;
const ImgContainer = styled.a`
  ${mixins.boxShadow};
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  position: relative;
  z-index: 1;
  background-color: ${colors.green};
  border-radius: ${theme.radius + 1}px;
  transition: ${theme.transition};
  ${media.tablet`height: 100%;`};
  ${media.thone`
    grid-column: 1 / -1;
    opacity: 0.25;
  `};
  &:hover,
  &:focus {
    background: transparent;
    &:before,
    ${FeaturedImg} {
      background: transparent;
      filter: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    transition: ${theme.transition};
    background-color: ${colors.navy};
    mix-blend-mode: screen;
  }
`;
const Project = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin-bottom: 100px;
  ${media.thone`
    margin-bottom: 70px;
  `};
  &:last-of-type {
    margin-bottom: 0;
  }
  &:nth-of-type(odd) {
    ${ContentContainer} {
      grid-column: 7 / -1;
      text-align: right;
      ${media.thone`
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
      `};
      ${media.phablet`padding: 30px 25px 20px;`};
    }
    ${TechList} {
      justify-content: flex-end;
      li {
        margin-left: ${theme.margin};
        margin-right: 0;
      }
    }
    ${Links} {
      justify-content: flex-end;
      margin-left: 0;
      margin-right: -10px;
    }
    ${ImgContainer} {
      grid-column: 1 / 8;
      ${media.tablet`height: 100%;`};
      ${media.thone`
        grid-column: 1 / -1;
        opacity: 0.25;
      `};
    }
  }
`;

const Featured = ({ data }) => {
  const revealTitle = useRef(null);
  const revealProjects = useRef([]);
  useEffect(() => {
    sr.reveal(revealTitle.current, srConfig());
    revealProjects.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const featuredProjects = data.filter(({ node }) => node.frontmatter.show === 'true');

  return (
    <FeaturedContainer id="projects">
      <Heading ref={revealTitle}>Opportunity and Objectives</Heading>

      <div>
        <ProjectName>Context</ProjectName>
        <p>
          PT. Great Giant Pineapple (GGP) was established in 1979. It now operates the largest
          integrated canned pineapple facility in the world, with its plantation and factory
          (including drum and can production) in one location. This integration gives GGP an edge in
          quality control and traceability of products, as well environmental sustainability through
          synergy in waste management. GGP's land covers 32,000 hectares in Lampung, of which 13,000
          is dedicated for growing pineapples. Annually, GGP processes more than 630,000 tons of
          pineapples and exports 13,000+ containers of canned pineaples to more than 60 countries.
        </p>

        <ProjectName>Problem and Opportunity</ProjectName>
        <p>
          According to{' '}
          <a href="https://swa.co.id/business-champions/great-giant-pineapple-mengekspor-produk-ke-lebih-dari-60-negara">
            Jane Fransisca
          </a>
          , Managing Director F&A and Business Development GGP, the company aimed to achieve 17,000
          FCL in 2023 and increase its canned pineapple production by 20,000 tons to 650,000 tons in
          2019. Which means, more work to do.
        </p>

        <p>More work for planning, estimation, counting, processing, scheduling, etc.</p>

        <p>
          There's a report by one of leading sector players that they only samples 5% of their
          tree’s for yield estimations because of the time it took for human counting. Given the
          significance of accurate harvest forecasts for pricing, supply chain and resource
          allocation; being able to sample 100% of the crop would add significant confidence for the
          business.
        </p>

        <p>
          Currently, GGP employs various technology including Drone and IoT for productivity and for
          achieving great traceability throughout all process: planting, harvesting, processing.
          However, to sustain the growth and achieve its goals, the company needs to continue to
          increase its productivity and efficiency. Businesses should look at all the ways
          technology can help them in order to stay ahead or, at least, avoid being surpassed and
          left behind.
        </p>

        <ProjectName>AI, 40% Productivity Gain, and Happier Employees</ProjectName>

        <b>Did you know that artificial intelligence can increase productivity by 40% or more?</b>
        <p></p>
        <p>
          Over the years there has been a rise in the use of technology in everyday businesses. But,
          this doesn’t simply mean the standard computers or hard drives we use in our daily
          routines. Newer technology, such as artificial intelligence (AI) is on the rise in the
          workplace especially because of the productivity benefits.
        </p>
        <p>
          According to a study by{' '}
          <a href="https://www.accenture.com/us-en/insight-artificial-intelligence-future-growth">
            <b>Accenture</b>
          </a>
          , artificial intelligence has the ability to increase productivity by 40% or more. Through
          data collection, automation, decision making, and cybersecurity, AI can boost
          profitability by an average of 38%. This can help free up valuable time for employees.
        </p>
        <p>
          According to TechRepublic, many companies are using AI to automate cognitive workflow. To
          help them cut down on tedious work, they use AI automation features to automate work
          that’s unpopular. This, in turn, has helped increase productivity because their employees
          and programmers are working on tasks they enjoy and take pride in.
        </p>

        <ProjectName>How can Neurafarm help?</ProjectName>

        <p>
          Our discussions in the last couple of weeks have been really fruitful. We have talked
          about several opportunity to leverage technology, especially Artificial Intelligence, to
          help GGP increase productivity and resource use efficiency. One of them being the
          application of Computer Vision for pineapple counting & ripeness detection. With this
          technology,{' '}
          <b>work that usually takes hours can be done within minutes or even seconds</b>. Neurafarm
          is really excited to be part of this project, because improving productivity and resource
          use efficiency in the agriculture industry through technology has always been our mission
          since Day 1.
        </p>

        <ProjectName>Benefits</ProjectName>
        <p>
        Machine intelligence is disrupting business for the better – with a tangible impact on bottom line performance. Put business data and smart algorithms together and you’ll feel the impact everywhere from media to finance and from sales to 
        customer support, in companies as diverse as start-ups and multinationals.
          
          Compared to human, AI can process data much more thoroughly with hundreds of times faster
          speed. Not only will it increase efficiency. It will also give more complete information
          and broader understading of the business operation. So that GPP can catch opportunity
          faster.
        </p>

        <p>
          By using video/image fly-by of all tree’s using a camera mounted on a vehicle (tractor or
          ATV) or drone and processed with Machine learning model to process the footage and
          accurately count the fruit on each tree, the final solution will enable GGP to get a much
          more accurate picture of their operation. With frequent fly-by’s, data could be collected
          to predict harvesting times and provide insights useful to the sales team and distribution
          customers. The business intelligence potential could help transform the operations of the
          business as well.
        </p>

        <ProjectName>Faster, More accurate data reporting</ProjectName>
        <p>
          Our system can be as 99% faster than manual method. It also enables more accurate data reporting because with this system you don't have to manually sample over your data again, enabling more accurate reporting and prediction.

        
          Because of its scalibility, this technology could help GGP throughout any stage of
          the business. GGP production could double in size, and without any additional staff, the
          software still can handle it better.
          
        </p>
        
        <ProjectName>Maximum flexibility to have it entirely your way</ProjectName>
        <p>
        Your business, your rules. Instead of having to work within the confines of the tool you’re using, Neurafarm’s flexibility allows you to choose one that can 
        be tailored to meet any business needs you may have. We’ll even give you the option of deploying our leading-edge machine learning on your own secure servers. Join us and discover that you can leap ahead while keeping control.
        </p>

      </div>

      <div>
        {featuredProjects &&
          featuredProjects.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { external, title, tech, github, cover } = frontmatter;

            return (
              <Project key={i} ref={el => (revealProjects.current[i] = el)}>
                <ContentContainer>
                  <FeaturedLabel>Product Vision</FeaturedLabel>
                  <ProjectName>
                    {external ? (
                      <a
                        href={external}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        aria-label="External Link">
                        {title}
                      </a>
                    ) : (
                      title
                    )}
                  </ProjectName>
                  <ProjectDescription dangerouslySetInnerHTML={{ __html: html }} />
                  {tech && (
                    <TechList>
                      {tech.map((tech, i) => (
                        <li key={i}>{tech}</li>
                      ))}
                    </TechList>
                  )}
                  <Links>
                    {github && (
                      <a
                        href={github}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        aria-label="Github Link">
                        <IconGithub />
                      </a>
                    )}
                    {external && (
                      <a
                        href={external}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        aria-label="External Link">
                        <IconExternal />
                      </a>
                    )}
                  </Links>
                </ContentContainer>

                <ImgContainer
                  href={external ? external : github ? github : '#'}
                  target="_blank"
                  rel="nofollow noopener noreferrer">
                  <FeaturedImg fluid={cover.childImageSharp.fluid} />
                </ImgContainer>
              </Project>
            );
          })}
      </div>
    </FeaturedContainer>
  );
};

Featured.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Featured;
