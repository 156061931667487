import React from 'react';
import PropTypes from 'prop-types';
import { IconLinkedin, IconInstagram, IconTwitter } from '@components/icons';

const FormattedIcon = ({ name }) => {
  switch (name) {
    case 'Linkedin':
      return <IconLinkedin />;
    case 'Instagram':
      return <IconInstagram />;
    default:
      return <IconTwitter />;
  }
};

FormattedIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormattedIcon;
